<template>
  <v-app>
  <div class="Noto">
    <v-container>
      <v-alert v-model="error" type="error" dismissible>
        {{dialogText}}
      </v-alert>
      <div class="title1" style="text-align: center; color:white;">二酸化炭素(CO2)濃度</div>
      <div class="title2" style="text-align: center; color:white;">厚生労働省推奨濃度：1000ppm以下</div>
      <v-row class="mt-3">
        <v-col cols="12" sm="12" md="6" lg="4" xl="4" v-for="item in listitems" v-bind:key="item.dev_id">
          <v-card class="text-center cardWidth mx-auto" color="rgba(128, 128, 128, 0.3)">
            <v-card-text class="devname">
              <div style="color:white; font-weight:bold;">{{ item.dev_name }}</div>
            </v-card-text>
            <v-card-text class="devco2">
              <div style="color:white; font-weight:bold;">{{ item.co2 }}</div>
              <div class="text-right" style="font-size:20pt; color:white; font-weight:bold; margin:30px 0px 0px 0px;">ppm</div>
            </v-card-text>
            <v-card-text>
              <div v-if="item.eva==='HIGH'" style="padding:10px; width:180px; margin:auto; color:orangered; font-size:32pt; border:solid 3px; border-radius:10px; font-weight:bold;">
                {{ item.eva }}
              </div>
              <div v-else-if="item.eva==='MID'" style="padding:10px; width:180px; margin:auto; color:lawngreen; font-size:32pt; border:solid 3px; border-radius:10px; font-weight:bold;">
                {{ item.eva }}
              </div>
              <div v-else style="padding:10px; width:180px; margin:auto; color:deepskyblue; font-size:32pt; border:solid 3px; border-radius:10px; font-weight:bold;">
                {{ item.eva }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
  </v-app>
</template>

<style>
  .Noto{
    font-weight:bold;
    background-image: url("../assets/photo0000-0515-sizeB.jpg");
    background-size: 100% 100%;
    height: 100%;
  }
  .title1{
    font-size:24pt;
  }
  .title2{
    font-size:16pt;
  }
  .devname{
    font-size:16pt;
    height:90px;
    margin:0px 0px -20px 0px;
  }
  .devco2{
    font-size:80pt;
    height:90px;
  }
  .cardWidth{
    width:380px;
  }
  @media (min-width: 600px){
    .title1{
      font-size:32pt;
    }
    .title2{
      font-size:20pt;
    }
  }
  @media (min-width: 720px){
    .title1{
      font-size:32pt;
    }
    .title2{
      font-size:20pt;
    }
  }
  @media (min-width: 800px){
    .title1{
      font-size:40pt;
    }
    .title2{
      font-size:22pt;
    }
  }
  @media (min-width: 960px){
    .title1{
      font-size:48pt;
    }
    .title2{
      font-size:24pt;
    }
  }
  @media (min-width: 1264px){
    .title1{
      font-size:64pt;
    }
    .title2{
      font-size:32pt;
    }
  }
</style>

<script>

export default {
  data () {
    return {
      lastdata: true,
      checkbox: true,
      timer:'',
      error:false,
      dialogText:null,
      
      sortBy:"last_update_time",
      sortDesc:true,
      hosturl: process.env.VUE_APP_APIURL,
      listitems: [],
    }
  },
  methods: {
    //評価判定
    getEvaluation:function(item, danmax, danmin, warmax, warmin) {
      var val = item.co2
      console.log(item)
      if(val === "－"){
        return 'LOW'
      } else {
        if (val >= danmax || val <= danmin) return 'HIGH'
        else if (val >= warmax || val <= warmin) return 'MID'
        else return 'LOW'
      }
    },
    
    //センサ情報取得処理
    getLastData:function(){
      console.log(this.$route.params.value)
      this.getSensorGroup(this.$route.params.value)
    },
    getLastDataMethod:function( groupid ){
      var sensorList = []

      //DynamoDB
      var apiurl = this.hosturl + "/public?sensorgroupid=" + groupid
        
      this.$axios.get(apiurl).then(res => {
        console.log(res)
        if(res['data']){
          res['data'].forEach(function ( data ) {
            //console.log(data)
            if(data['dev_id'].indexOf('evs_') != -1){
              data['dev_id'] = data['dev_id'].replace('evs_','')
            }
            if(data['last_update_time']){
              //小数切り捨て
              data['co2'] = parseInt(data['co2'], 10)
            } else {
              //最終更新日時がない（データが未送信）の場合、「－」を表示
              data['co2'] = "－"
            }
            data['eva'] = this.getEvaluation(data, data['co2_warningThresholdHigh'], data['co2_warningThresholdLow'], data['co2_cautionThresholdHigh'], data['co2_cautionThresholdLow']);
            sensorList.push(data)
          }, this);
        }
      })
      .catch((err) => {
        console.log(err);
        this.dialogText = 'センサ情報の取得に失敗しました。'
        this.error = true;
      });
      
      sensorList.sort(function(a, b){
        if(a.dev_name > b.dev_name){
          return -1;
        } else {
          return 1;
        }
      });
      
      this.listitems = sensorList
    },
    getSensorGroup:function( groupid ){
      var apiurl = this.hosturl + "/public/" + groupid
      this.$axios.get(apiurl).then(res => {
        console.log(res['data'])
        if(res['data']){
          if(res['data'][0]['published']){
            this.getLastDataMethod(groupid)
          } else {
            this.dialogText = '非公開のセンサグループです。'
            this.error = true;
            return
          }
        } else {
          this.dialogText = '指定したセンサグループは存在していません。'
          this.error = true;
          return
        }
      })
      .catch((err) => {
        console.log(err);
        this.dialogText = 'センサグループの取得に失敗しました。'
        this.error = true;
      });
    },
    
    //timerスタート
    timerStart:function(){
      var fn = () =>this.getLastData();
      this.timer = setInterval(fn,60000);
    },
    
    //timerストップ
    timerStop:function(){
      clearInterval(this.timer)
    },
  },
  //画面表示時（部品配置後）
  mounted() {
    this.getLastData()
    this.timerStart()
  },
  beforeDestroy(){
    this.timerStop()
  }
}
</script>
